
import { Options, prop, Vue } from "vue-class-component";
import SelectGroup from "@/app/ui/components/select-group/index.vue";
import { countries } from "@/app/infrastructures/misc/BulkData";
import maxLengthPhoneNumber from "@/app/infrastructures/misc/common-library/MaxLengthPhoneNumber";
import { nationalPrefixExist } from "@/app/ui/views/shipment/booking/booking-utils";
import { AccountController } from "@/app/ui/controllers/AccountController";

class Props {
  phone = prop<string>({
    default: "",
    type: String
  });
  code = prop<string>({
    default: "",
    type: String
  });
  flag = prop<string>({
    default: "",
    type: String
  });
  error = prop<any>({
    default: {},
    type: Object
  });
  disabled = prop<boolean>({
    default: false,
    type: Boolean
  });
  column = prop<string>({
    default: "",
    type: String
  });
  prefixWidth = prop<string>({
    default: "w-2/12",
    type: String
  });
}

@Options({
  emits: [
    "setFieldIndex",
    "setErrorPhone",
    "setPhone",
    "setErrorPhoneCode",
    "setPhoneCode",
    "setPhoneFlag",
    "checkFocus",
    "setErrorCharMinimal"
  ],
  components: {
    SelectGroup
  }
})
export default class SelectPhoneCountry extends Vue.with(Props) {
  countryFiltered = countries;
  get allCountry() {
    return this.countryFiltered;
  }

  set allCountry(data: Array<any>) {
    this.countryFiltered = data;
  }

  foundDataPhoneCode(value: string) {
    this.allCountry = countries.filter((item: any) => {
      return Object.keys(item).some(key =>
        item[key].toLowerCase().includes(value.toLowerCase())
      );
    });
  }

  setValueCountry(item: any) {
    this.$emit("setErrorPhoneCode", false);
    this.$emit("setPhoneCode", item.code);
    this.$emit("setPhoneFlag", item.flag);
    this.$emit("checkFocus");
    this.validatePhone(this.phone, item.code);
  }

  onFilterCountry(value: string) {
    if (this.code.length === 0) {
      this.$emit("setPhoneFlag", "");
    }
    this.foundDataPhoneCode(value);
    this.checkErrorPhoneCode(value);
  }

  checkErrorPhoneCode(value: string) {
    const codeExist = countries.find((item: any) => item.code === value);

    if (!codeExist) {
      this.$emit("setErrorPhoneCode", true);
    } else {
      this.$emit("setErrorPhoneCode", false);
    }

    this.$emit("setPhoneCode", value);
  }

  maxLengthPhoneNumber(phone: string) {
    return maxLengthPhoneNumber(phone);
  }

  formatNumber(value: string) {
    const prefix = this.code === "+62" ? /^62|^0|[\D]+/g : /[\D]+/g;
    return value.replace(prefix, "");
  }

  validatePhone(value: string, code = "") {
    const codePrefix = code !== "" ? code : this.code;
    this.$emit("checkFocus");
    const nationalPrefix = nationalPrefixExist(codePrefix);
    let newValue = this.indonesianPhone(value, codePrefix);
    if (newValue.startsWith(nationalPrefix)) {
      newValue = newValue.slice(nationalPrefix.length);
    }
    const phoneArray = newValue.split("");
    if (this.detectPhoneNumber(phoneArray) && phoneArray.length > 14) {
      const newPhone = newValue.slice(0, -1);
      this.$emit("setPhone", newPhone);
    } else {
      this.$emit("setPhone", newValue);
    }

    this.$emit("setErrorPhone", newValue);
    this.setErrorMinimalChar(newValue);
  }

  detectPhoneNumber(phoneArray: Array<string>) {
    return phoneArray.find(
      () => phoneArray[0] === "0" && phoneArray[1] === "8"
    );
  }

  setFieldIndex() {
    this.$emit("setFieldIndex");
  }

  pasteText() {
    let newValue;
    const nationalPrefix = nationalPrefixExist(this.code);
    newValue = this.indonesianPhone(this.phone, this.code);
    if (newValue.startsWith(nationalPrefix)) {
      newValue = newValue.slice(nationalPrefix.length);
    }

    this.$emit("setPhone", newValue);
    this.setErrorMinimalChar(newValue);
  }

  setErrorMinimalChar(newValue: string) {
    if (newValue.length < 5) {
      this.$emit("setErrorCharMinimal", true);
    } else {
      this.$emit("setErrorCharMinimal", false);
    }
  }

  indonesianPhone(phone: string, code: string) {
    let newPhone = phone;
    if (phone.slice(0, 2) === "62" && code === "+62") {
      newPhone = phone.slice(2);
    }
    return newPhone;
  }

  get errorMandatory() {
    if (this.column === "Pengirim") {
      return this.isNonForeignAccount ? `No. Telepon ${this.column} wajib diisi` : this.$t('bookingShipment.error.senderNamePhone')
    } else {
      return this.isNonForeignAccount ? `No. Telepon ${this.column} wajib diisi` : this.$t('bookingShipment.error.recipientNamePhone')
    }
    
  }

  get isNonForeignAccount() {
    return !AccountController.accountData.accountIsForeign
  }
}
