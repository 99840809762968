export const MESSAGE_TYPE_OPTIONS = [
  {
    name: "SMS Premium",
    value: "sms_premium"
  },
  {
    name: "SMS Regular",
    value: "sms_reguler"
  },
  {
    name: "WA Longcode",
    value: "wa_longcode"
  },
  {
    name: "WA Premium",
    value: "wa_premium"
  }
];

export const EVENT_STATUS_OPTIONS = [
  {
    name: "BKD",
    value: "BKD"
  },
  {
    name: "POD",
    value: "POD"
  },
  {
    name: "DEX",
    value: "DEX"
  },
  {
    name: "DEL",
    value: "DEL"
  },
  {
    name: "CODREJ",
    value: "CODREJ"
  },
  {
    name: "STI-DEST",
    value: "STI-DEST"
  },
  {
    name: "HAL",
    value: "HAL"
  },
  {
    name: "CI",
    value: "CI"
  },
  {
    name: "CNX",
    value: "CNX"
  }
];

export const PACKAGE_TYPE_OPTIONS = [
  {
    name: "COD",
    value: "cod"
  },
  {
    name: "Non COD",
    value: "non_cod"
  }
];

export const TARGET_MESSAGE_OPTIONS = [
  {
    name: "Penerima Paket",
    value: "penerima_paket"
  },
  {
    name: "Pengirim Paket",
    value: "pengirim_paket"
  }
];

export const APPLIED_TO_MESSAGE_OPTIONS = [
  {
    name: "POS",
    value: "pos"
  },
  {
    name: "Client",
    value: "client"
  }
];

export const TEMPLATE_MESSAGE: { [key: string]: any } = {
  BKD: {
    cod:
      "Hi {Consignee Name},\n" +
      "Paket COD Anda senilai {COD_amount} dari pengirim {Shipper Name} telah dibooking oleh Lion Parcel \n" +
      "pada {Datetime} dengan No. STT {STT Number}. Paket Anda akan sampai dengan estimasi pengiriman {Delivery_SLA}. Terimakasih telah menggunakan layanan Lion Parcel ! :)\n" +
      "\n" +
      "Cek status paket Anda di https://lionparcel.com/ atau cek pada Apps Lion Parcel http://bit.ly/LioApps ",
    nonCod:
      "Hi {Consignee Name},\n" +
      "Paket Anda dari pengirim {Shipper Name} telah dibooking oleh Lion Parcel pada {Datetime} dengan No. STT {STT Number}. Paket Anda akan sampai dengan estimasi pengiriman {Delivery_SLA}. Terimakasih telah menggunakan layanan Lion Parcel ! :)\n" +
      "\n" +
      "Cek status paket Anda di https://lionparcel.com/ atau cek pada Apps Lion Parcel http://bit.ly/LioApps"
  },
  POD: {
    cod:
      "Hi {Consignee Name},\n" +
      "Paket COD Anda senilai {COD_amount} dari pengirim {Shipper Name} dengan No. STT {STT Number} \n" +
      "dan estimasi pengiriman {Delivery_SLA} telah diterima oleh {recipient_name} pada {datetime}. \n" +
      "Kurir Lion Parcel yang mengantar paket Anda adalah {driver_name} dengan No. Telp \n" +
      "{driver_phone_number}. Terimakasih telah menggunakan Lion Parcel ! :)\n" +
      "\n" +
      "Jika paket belum Anda terima atau paket rusak mohon hubungi CS kami di 02180820072 atau Klaim \n" +
      "paket pada Apps Lion Parcel http://bit.ly/LioApps",
    nonCod:
      "Hi {Consignee Name},\n" +
      "Paket Anda dari pengirim {Shipper Name} dengan No. STT {STT Number} dan estimasi pengiriman {Delivery_SLA} telah diterima oleh {recipient_name} pada {datetime}. Kurir Lion Parcel yang \n" +
      "mengantar paket Anda adalah {driver_name} dengan No. Telp {driver_phone_number}. \n" +
      "Terimakasih telah menggunakan Lion Parcel ! :)\n" +
      "\n" +
      "Jika paket belum Anda terima atau paket rusak mohon hubungi CS kami di 02180820072 atau Klaim \n" +
      "paket pada Apps Lion Parcel http://bit.ly/LioApps"
  },
  DEX: {
    cod:
      "Hi {Consignee Name},\n" +
      "Paket COD Anda senilai {COD_amount} dari pengirim {Shipper Name} dengan No. STT {STT Number} \n" +
      "dan estimasi pengiriman {Delivery_SLA} tidak terkirim karena {undelivered_reason} pada {datetime}. \n" +
      "Kurir Lion Parcel yang mengantar paket Anda adalah {driver_name} dengan No. Telp \n" +
      "{driver_phone_number}. Hubungi Customer Service kami untuk informasi lebih lanjut di 02180820072. Terimakasih telah menggunakan Lion Parcel ! :) \n" +
      "\n" +
      "Cek status paket Anda di https://lionparcel.com/ atau cek pada Apps Lion Parcel http://bit.ly/LioApps",
    nonCod:
      "Hi {Consignee Name},\n" +
      "Paket Anda dari pengirim {Shipper Name} dengan No. STT {STT Number} dan estimasi pengiriman {Delivery_SLA} tidak terkirim karena {undelivered_reason} \n" +
      "pada {datetime}. Kurir Lion Parcel yang mengantar paket Anda adalah {driver_name} dengan \n" +
      "No. Telp {driver_phone_number}. Hubungi Customer Service kami untuk informasi lebih lanjut di \n" +
      "021-80820072. Terimakasih telah menggunakan Lion Parcel ! :) \n" +
      "\n" +
      "Cek status paket Anda di https://lionparcel.com/ atau cek pada Apps Lion Parcel http://bit.ly/LioApps"
  },
  DEL: {
    cod:
      "Hi {Consignee Name},\n" +
      "Paket COD Anda senilai {COD_amount} dari pengirim {Shipper Name} dengan No. STT {STT Number} \n" +
      "dan estimasi pengiriman {Delivery_SLA} sedang dalam pengantaran ke alamat Anda pada {datetime}. \n" +
      "Kurir Lion Parcel yang mengantar paket Anda adalah {driver_name} dengan No. Telp \n" +
      "{driver_phone_number}. Mohon siapkan biaya COD sesuai dengan paket pesanan Anda. Terimakasih \n" +
      "telah menggunakan Lion Parcel ! :) \n" +
      "\n" +
      "Cek status paket Anda di https://lionparcel.com/ atau cek pada Apps Lion Parcel http://bit.ly/LioApps",
    nonCod:
      "Hi {Consignee Name},\n" +
      "Paket Anda dari pengirim {Shipper Name} dengan No. STT {STT Number} dan estimasi pengiriman {Delivery_SLA} sedang dalam pengantaran ke alamat Anda pada {datetime}. Kurir Lion Parcel yang \n" +
      "mengantar paket Anda adalah {driver_name} dengan No. Telp {driver_phone_number}. Mohon \n" +
      "siapkan biaya COD sesuai dengan paket pesanan Anda. Terimakasih telah menggunakan layanan \n" +
      "Lion Parcel ! :) \n" +
      "\n" +
      "Cek status paket Anda di https://lionparcel.com/ atau cek pada Apps Lion Parcel http://bit.ly/LioApps "
  },
  CODREJ: {
    cod:
      "Hi {Consignee Name},\n" +
      "Paket COD Anda senilai {COD_amount} dengan No. STT {STT Number} dan estimasi pengiriman \n" +
      "{Delivery_SLA} telah kami kembalikan kepada pengirim {Shipper Name} karena {CODREJ_reason} \n" +
      "pada {datetime}. Kurir Lion Parcel yang mengantar paket Anda adalah {driver_name} dengan \n" +
      "No. Telp {driver_phone_number}. Terimakasih telah menggunakan layanan Lion Parcel ! :) \n" +
      "\n" +
      "Cek status paket Anda di https://lionparcel.com/ atau cek pada Apps Lion Parcel http://bit.ly/LioApps"
  }
};

export const parsingValueToNameOptions = (array: Array<any>, value: string) => {
  if (!value) {
    return "";
  }
  return array.find((item: any) => {
    return item.value == value;
  })?.name;
};
